.defaultnav
{
    
    align-self: center;
    /* width: 90%; */
    margin-top: 50px;
    margin-left: 2%;
    margin-right: 2%;
    padding: 0px;
    background-color: rgb(240, 240, 240) !important;
    transition: margin 0.5s,  padding 0.5s;
    border-radius: 0.25rem;

    
}
.navbar-brandd{padding: 0px; margin: 0px;}
.sticky
{
    
    margin-left: 0%;
    margin-right: 0%;
    padding-left: 2%;
    padding-right: 2%;
    border-radius: 0px;
    /* -------------------------------------- */

    /* background-color: blue !important; */
}
.name
{
    margin-top: 65px;
    /* margin-top: calc(100vh / 4 - 50px); */
    font-family: 'Poppins', sans-serif;
    position: relative;
    z-index: 9;
    text-align: center;
    font-size: 9vw;
    font-weight: 600;
    color: white;
    text-shadow:   -1px -1px 0 #000,  
                   1px -1px 0 #000,
                   -1px 1px 0 #000,
                   1px 1px 0 #000;
}

.title
{
    position: relative;
    z-index: 9;
    text-align: center;
    font-size: 3vw;
    font-weight: 400;
    color: white;
    text-shadow:   -1px -1px 0 #000,  
                   1px -1px 0 #000,
                   -1px 1px 0 #000,
                   1px 1px 0 #000;

}
.sep
{
    margin-top: 5px;
    margin-bottom: 5px;
    display: inline-block; border: 1px solid rgba(165, 165, 165, 0.349); height: 80%;
}
.sep2
{
    display: inline-block; border: 1px solid rgba(165, 165, 165, 0.349);
    width: 100%;

}
.navLinkk
{
   padding-left: 15px;
   padding-right: 15px;
   height: 50px;
   display: flex;
   align-items: center;
   transition: border 0.1s;
   
}
.navLinkk:hover
{border-bottom: 3px solid rgb(0, 131, 155);


}

.navLink
{
    color: black !important;
    font-size: 18px;
    font-weight: 500;
    transition: color 0.1s;
}
.navLink:hover
{color: rgb(0, 131, 155) !important;


}
.navBtn
{
color: white;
height: 50px;
position:absolute;
right: 0px;
margin-right: 0%;
transition: margin 0.5s;
font-size: 18px;
font-weight: 400;

}
.navBtnn
{ margin-right: 2%;

}
.BtnCouble
{
    position: relative;
    z-index: 9;
    width: 100%;
    height: 45px;
    margin-top: 20px;
    color: white;
    font-size: 23px;
    font-weight: 500;
}
.BtnCoubleCont
{
    margin-left: 30%;
    margin-right: 30%;
    
} 
.vidCont{
    background-size: cover;
    /* background-attachment: fixed; */
    background-position: center;
    display: flex; justify-content: center; height: 100vh; width: 100%; background-color: rgba(63, 63, 63, 0.384); overflow: hidden; position: absolute; top: 0px; z-index: 1;}
.headerVid{ height: 1080px; width: 1920px; position: relative; left: 0px; top: -50px;}
.mobNav
{
    background-color: white;
    display: none;
    position: sticky;
    top: 0px;
    z-index: 999;
    border-bottom: 2px solid black;


}
.bars{position: absolute; left: 0px; margin-left: 10px;}
.bars:hover
{
    cursor: pointer;
}





.modal.left .modal-dialog {
    position:fixed;
    left: 0;
    margin: auto;
    width: 320px;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
-o-transform: translate3d(0%, 0, 0);
transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content {
height: 100%;
overflow-y: auto;
}


/* ----- MODAL STYLE ----- */
.modal-content {
border-radius: 0;
border: none;
}

.modalBody
{
padding: 0px;
}
.namee
{
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: 'Poppins', sans-serif;
    position: relative;
    z-index: 9; 
    text-align: center;
    font-size: 9vw;
    font-weight: 600;
    color: white;
   
}
.sideContent
{
color: white;
margin-left: 20px;
font-size: 22px;
font-weight: 500;
}

.sideContent:active
{
color: rgb(0, 190, 193);
}
.modall
{
background-color: rgb(39, 39, 56);
}
.logo{width:50px;}
.logoMob{height: 100%;}




@media screen and (max-width: 1080px) 
{

    .BtnCoubleCont{
        
        margin-left: 10%;
        margin-right: 10%;
    }

    

}


@media screen and (max-width: 750px) 
{
    .defaultnav{display: none;}
    .mobNav{
    display: flex;
    height: 50px;
    justify-content: center;
    align-items: center;
    }
    .name
    {
        margin-top: 165px;
        font-size: 13vw;
    }
    .namee{font-size: 13vw;}
    .title{font-size: 5vw;}
    .BtnCoubleCont{
        
        margin-left: 10%;
        margin-right: 10%;
    }
    .vidCont{top: 50px;}
    

}
@media screen and (max-width: 750px) 
{

    .vidCont{
        height: calc(100vh - 50px );
    }

}








































.form-controll {
    height: 52px;
    background: transparent;
    color: rgba(255, 255, 255, 0.8) !important;
    font-size: 14px;
    border-radius: 0px;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: 1px solid rgba(255, 255, 255, 0.2); }
    .form-controll::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: rgba(255, 255, 255, 0.5) !important; }
    .form-controll::-moz-placeholder {
      /* Firefox 19+ */
      color: rgba(255, 255, 255, 0.5) !important; }
    .form-controll:-ms-input-placeholder {
      /* IE 0+ */
      color: rgba(255, 255, 255, 0.5) !important; }
    .form-controll:-moz-placeholder {
      /* Firefox 18- */
      color: rgba(255, 255, 255, 0.5) !important; }
    .form-controll:focus, .form-controll:active {
      background: transparent;
      border-color: rgba(255, 255, 255, 0.5) !important; }
  
  textarea.form-controll {
    height: inherit !important; }
  
  .wrapper {
    width: 100%;
    /* -webkit-box-shadow: 0px 21px 41px -13px rgba(0, 0, 0, 0.18);
    -moz-box-shadow: 0px 21px 41px -13px rgba(0, 0, 0, 0.18);
    box-shadow: 0px 21px 41px -13px rgba(0, 0, 0, 0.18);  */
}

  
  .contact-wrap {
    background: #056c95;
    background: -moz-linear-gradient(-45deg, #056c95 0%, #320466 100%);
    background: -webkit-gradient(left top, right bottom, color-stop(0%, #056c95), color-stop(100%, #320466));
    background: -webkit-linear-gradient(-45deg, #056c95 0%, #320466 100%);
    background: -o-linear-gradient(-45deg, #056c95 0%, #320466 100%);
    background: -ms-linear-gradient(-45deg, #056c95 0%, #320466 100%);
    background: -webkit-linear-gradient(315deg, #056c95 0%, #320466 100%);
    background: -o-linear-gradient(315deg, #056c95 0%, #320466 100%);
    background: linear-gradient(135deg, #056c95 0%, #320466 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#056c95', endColorstr='#320466', GradientType=1 ); }
    .contact-wrap h3 {
      color: #fff; }
  
  .info-wrap h3 {
    color: #000;
    position: relative; }
    .info-wrap h3:after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 60px;
      height: 3px;
      background: #008dc4;
      content: ''; }
  
  .info-wrap .dbox {
    width: 100%;
    margin-bottom: 25px; }
    .info-wrap .dbox:last-child {
      margin-bottom: 0; }
    .info-wrap .dbox p {
      margin-bottom: 0; }
      .info-wrap .dbox p span {
        font-weight: 400;
        color: #000; }
      .info-wrap .dbox p a {
        color: rgba(0, 0, 0, 0.3); }
    .info-wrap .dbox .icon {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 1px solid rgba(0, 0, 0, 0.05); }
      .info-wrap .dbox .icon span {
        font-size: 20px;
        color: #000; }
    .info-wrap .dbox .text {
      width: calc(100% - 50px); }
  
  .btnn {
    padding: 12px 16px;
    cursor: pointer;
    border-width: 1px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 400;
    -webkit-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
    position: relative;
    margin-bottom: 20px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s; }
    @media (prefers-reduced-motion: reduce) {
      .btnn {
        -webkit-transition: none;
        -o-transition: none;
        transition: none; } }
    .btnn:hover, .btnn:active, .btnn:focus {
      outline: none !important;
      -webkit-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.22) !important;
      -moz-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.22) !important;
      box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.22) !important; }
    .btnn.btn-primary {
      background: #00abef !important;
      border-color: #00abef !important;
      color: #fff; }
      .btnn.btn-primary:hover, .btnn.btn-primary:focus {
        border-color: #008dc4 !important;
        background: #008dc4 !important; }
  
  .contactForm .form-controll {
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding: 0; }
  
  #contactForm .error {
    color: #f65c78;
    font-size: 12px; }
  
  #contactForm .form-controll {
    font-size: 16px; }
  
  #message {
    resize: vertical; }
  
  #form-message-warning, #form-message-success {
    display: none; }
  
  #form-message-warning {
    color: #f65c78; }
  
  #form-message-success {
    color: #28a745;
    font-size: 18px;
    font-weight: 500; }
  
  .submitting {
    float: left;
    width: 100%;
    padding: 10px 0;
    display: none;
    font-size: 16px;
    font-weight: 500;
    color: #28a745; }


    .modalContCustom
    {
        width: 90vw !important;
        max-width: 100vw !important;
        
    }
    .aaasssddd
    {
        margin-top: 12px;
    }

    #MessageMeModal{padding: 0px !important;
    
    }
    .qqqqq{padding: 0px !important;}


    .loadingScreenContainer
    {
      position:absolute;
      top: 0px;
      display: flex;
      justify-content: center;
      align-items:flex-start;
      background: rgb(255, 255, 255);
      width: 100%;
      height: 1000vh;
      z-index: 999999999;
    }
    .displayNoneClass
    {
      display: none !important;
    }
    .loadingGif
    {
      margin-top: calc(50vh - 140px);
    }
    .noScrollBodyClass
    {
      overflow-y:hidden !important ;
      overflow-x: hidden !important;
    }
    .bodyd
    {
      overflow-y:hidden !important ;
      overflow-x: hidden !important;
    }
    .btnnbtnn
    {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .loadingSpinnerMail
    {
      display: none;
      width: 1rem !important;
      height: 1rem !important;
      margin-right: 10px;
    }
