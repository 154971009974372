


.portCard
{

    display: inline-block;
    width: 300px;
    height: 150px;
    overflow: hidden;
    box-shadow: 6.5px 6.5px 7px rgb(184, 184, 184) ;
    margin: 15px 0px;

}
.portCardImg
{
    

    /* width: 400px;
    height: 200px; */

    width: 100%;
    object-position: center bottom;


}
.cardContent
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 200px;
    position: relative;
    top: -170px;
    z-index: 99;

    background-color: rgba(61, 61, 61, 0.685);
    opacity: 0;
    transition: all 0.5s;
    

    

}

.cardContent:hover
{
    cursor:default;
    opacity: 1;
}
.portProjName
{
    color: white;
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 10px;
    

}
.portProjBtn
{
    font-size: 17px;
    font-weight: 400;
    border-width: 2px;
}
/* ////////////////////////////////////////////// */
@media screen and (max-width: 800px) 
{
    
    /* .portCard{width: 350px !important;}
    .portCardImg{width: 350px !important;}
    .cardContent{width: 350px !important;} */
}
@media screen and (max-width: 350px) 
{
   

    /* .portCard{width: 300px !important;}
    .portCardImg{width: 300px !important;}
    .cardContent{width: 300px !important;} */
}