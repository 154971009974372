
@media screen and (max-width: 800px) 
{
    
    .portCardd{width:    290px !important; height: 175px !important;}
    .portCardImgg{width: 290px !important; height: 175px !important;}
    .cardContentt{width: 290px !important;}
}
@media screen and (max-width: 350px) 
{
   

    .portCardd{width: 300px !important;}
    .portCardImgg{width: 300px !important;}
    .cardContentt{width: 300px !important;}
}
/* ////////////////////////////////////////////// */
.portCardd
{

    display: inline-block;
    width: 400px;
    height: 200px;
    overflow: hidden;
    /* box-shadow: 6.5px 6.5px 7px rgb(184, 184, 184) ; */
    margin: 0px 0px -6px 0px;
    border: 1px solid black;

}
.portCardImgg
{
    

    width: 400px;
    height: 200px;



}
.cardContentt
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 400px;
    height: 200px;
    position: relative;
    top: -200px;
    z-index: 99;

    background-color: rgba(61, 61, 61, 0.685);
    opacity: 0;
    transition: all 0.5s;
    

    

}

.cardContentt:hover
{
    cursor:default;
    opacity: 1;
}
.portProjNamee
{
    color: white;
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 10px;
    

}
.portProjBtnn
{
    font-size: 17px;
    font-weight: 400;
    border-width: 2px;
}