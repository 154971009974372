.portCont
{


    /* background-image: url("pbg - Copy.jpeg"); */
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
}
/* .portTitle
{
    color: white;
    font-size: 140px;
    font-weight: 500;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}
.portTitlee
{
    
    color: white;
    font-size: 75px;
    font-weight: 400;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;

} */
.portBtn
{
    /* color: white !important; */
    width: 200px;
    font-weight: 500;
    border-width: 3px;
   
}
.portBtnCont
{
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 40px;
   
}
.portBtnContt
{
    display: none;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 40px;
    
}
.portLeft
{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    
}
.portRight
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}


.carousel-indicators-container
{
    background-color: rgba(0, 0, 0, 0.512);
    width: fit-content;
    position: absolute;
    margin: auto;
    border-radius: 10px;
}
.arrowCar{
    background-color: rgb(0, 0, 0);
    width: 20px;
    height: 20px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}