.stayConnected
            {
                background-color: rgb(37, 37, 49);
                border-top: 5px solid black;
                display: inline-block;
                width: 100%;
                /* height: 150px; */
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            .stayConnectedTitle
            {
                font-size: 23px;
                font-weight: 700;
                color: white;
                margin-top: 10px;
                
            }
            .stayConnectedBtns
            {
                display: flex;
                justify-content: center;

            }
            .contactO
            {
                display: inline-block;
                width: 55px;
                height: 55px;
                border: 1px solid rgba(255, 255, 255, 0.582);
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                margin: 10px;

            }
            .contactIF
            {
                display: inline-block;
                width: 40px;
                height: 40px;
                border: 3px solid rgba(255, 255, 255, 0.582);
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                color: rgba(255, 255, 255, 0.582);

            }
            .contactIY
            {
                display: inline-block;
                width: 40px;
                height: 40px;
                border: 3px solid rgba(255, 255, 255, 0.582);
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                color: rgba(255, 255, 255, 0.582);

            }
            .contactIL
            {
                display: inline-block;
                width: 40px;
                height: 40px;
                border: 3px solid rgba(255, 255, 255, 0.582);
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                color: rgba(255, 255, 255, 0.582);

            }
            .contactO:hover
            {
                cursor: pointer;
                border: 1px solid rgba(255, 255, 255, 0.822);
            }
            .footerA{
                text-decoration: none;
            }
            .footerA:hover
            {
                text-decoration: none;
            }
            .contactO:hover .contactIF
            {
                cursor: pointer;
                border: 3px solid rgba(255, 255, 255, 0.822);
                color: rgba(6, 85, 255, 0.822);
                
            }
            .contactO:hover .contactIY
            {
                cursor: pointer;
                border: 3px solid rgba(255, 255, 255, 0.822);
                color: rgba(255, 0, 0, 0.822);
            }
            .contactO:hover .contactIL
            {
                cursor: pointer;
                border: 3px solid rgba(255, 255, 255, 0.822);
                color: rgba(43, 163, 255, 0.822);
            }
            .contactMeBtn
            {
                color: white !important;
                margin: 10px 0px 20px 0px ;
                transform: skew(25rad);
            }
            .contactMeBtn:hover
            {
                color: rgb(0, 0, 0) !important;
            }


            .footer
            {
                /* padding: 15px 0px 50px 0px; */
                background-color: rgba(0, 0, 255, 0.116);
                
                display: inline-block;
                width: 100%;
                /* height: 150px; */
                display: flex;
                flex-direction: column;
                align-items: center;
                border-bottom: 10px solid black;
            }
            .footerName
            {
                font-size: 2rem;
                font-weight: 700;
                margin: 10px 0px;

            }
            .footerLogo
            {
                width: 50px;
                height: 50px;
                border-radius: 50%;
            }
            .footerLogo:hover
            {
                cursor: pointer;
            }
            .footerSep
            {
                display: inline-block;
                font-size: 16px;
                margin: 0px 5px;
                /* height: 100%;
                border: 1px solid black; */
            }
            .footerLinks
            {
                margin: 10px 0px 20px 0px;
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
            }