.projectSingleCardContainer
{
    margin: 30px 20px;
    position: relative;
    display: inline-block;
    border: solid black 1px;
    width: 300px;
    height: fit-content;
    border-radius: 15px;
    overflow: hidden;
    padding-bottom: 50px;
}
.projectSingleCardImageContainer
{
    background-color: rgb(185, 185, 185);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 150px;
    overflow: hidden;
    border-bottom: 1px solid black;
}
.projectSingleCardImage
{
    /* width: 100%; */
    
    height: 100%;

}

.projectSingleCardTitle
{
    margin: 5px 10px;
}
.projectSingleCardDiscription
{
    margin: 0px 10px;
    font-size: 15px;                    
    background-color: rgb(255, 255, 255);
    
    height: 60px;
    overflow: hidden;




}
.projectSingleCardDiscription p
{
    display: -webkit-box;   
    -webkit-line-clamp: 2;   
    -webkit-box-orient: vertical;     
    overflow: hidden; 
}
.projectSingleCardTools
{
    padding: 0px 8px;
    color: rgb(167, 167, 167);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
    
}

.projectSingleCardButton
{
    width: 100%;
    border-radius: 0px !important;
    position:absolute;
    bottom: 0px;
}




.projectSingleCardPageContainer
{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}